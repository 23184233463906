<template>
  <v-row dense>
    <v-col v-if="!isFixed" cols="12" md="2">
      <v-text-field v-model="row.from" label="De:" outlined rounded dense hide-details />
    </v-col>
    <v-col v-if="!isFixed" cols="12" md="2">
      <v-text-field v-model="row.to" label="Até:" outlined rounded dense hide-details />
    </v-col>
    <v-col cols="12" md="2">
      <v-text-field v-model="row.cost" label="Custo:" v-mask="['#,##', '##,##', '###,##']" outlined rounded dense
        hide-details />
    </v-col>
    <v-col v-if="rows.length > 1" class="d-flex align-center" cols="12" md="1">
      <v-btn icon @click="$emit('removeRow')">
        <v-icon color="error" small>fas fa-times</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    row: Object,
    rows: Array,
    option: Boolean,
    tableIndex: Number,
    isFixed: Boolean
  },
};
</script>